<template>
  <div class="exam-data-layout">
    exam data
    <Table :columns="columns" :data="examData">
      <template slot-scope="{ row }" slot="passRatio">
        <div>{{ Math.round(row.passRatio * 100) / 100 }}%</div>
      </template>
    </Table>
    <Page :total="total" size="small" @on-change="handlePageChange" />
  </div>
</template>

<script>
import ExamAPI from '@/api/exam'
export default {
  name: 'ExamData',
  data() {
    return {
      examData: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      columns: [
        {
          key: 'examName',
          title: '考试名称'
        },
        {
          key: 'participateNum',
          title: '参与人数'
        },
        {
          key: 'passNum',
          title: '及格人数'
        },
        {
          slot: 'passRatio',
          title: '及格率'
        },
        {
          key: 'averageScore',
          title: '平均分'
        },
        {
          key: 'maxScore',
          title: '最高分'
        }
      ]
    }
  },
  created() {
    this.requestAllExamData()
  },
  methods: {
    async requestAllExamData() {
      const { res } = await ExamAPI.getAllExamStatistics(this.currentPage - 1, this.pageSize)
      this.examData = res.data
      this.total = res.total
    },
    handlePageChange(newPage) {
      this.currentPage = newPage
    }
  },
  watch: {
    currentPage() {
      this.requestAllExamData()
    }
  }
}
</script>

<style lang="less" scoped></style>
