import { render, staticRenderFns } from "./StuffStatistic.vue?vue&type=template&id=07497b04&scoped=true&"
import script from "./StuffStatistic.vue?vue&type=script&lang=js&"
export * from "./StuffStatistic.vue?vue&type=script&lang=js&"
import style0 from "./StuffStatistic.vue?vue&type=style&index=0&id=07497b04&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07497b04",
  null
  
)

export default component.exports