<template>
  <div class="overview-data">
    <div class="wrapper">
      <Divider>基本数据</Divider>
      <JYNumberDisplayer v-if="stuffCount !== null" :value="stuffCount" label="员工总数" icon="#icon-depart2" />
      <JYNumberDisplayer v-if="courseCount !== null" :value="courseCount" label="课程总数" icon="#icon-tiku" />
      <JYNumberDisplayer v-if="questionCount !== null" :value="questionCount" label="试题总数" icon="#icon-hf_huabi_fill" />
      <JYNumberDisplayer v-if="paperCount !== null" :value="paperCount" label="试卷总数" icon="#icon-file-info" />
      <Divider>管理员数据</Divider>
      <div class="chart-wrapper">
        <DatePicker
          :editable="false"
          :clearable="false"
          v-model="dateRange"
          type="daterange"
          split-panels
          placeholder="选择起止时间"
          style="width: 200px; margin-left: 30px; margin-top: 20px"
        ></DatePicker>
        <v-echarts :options="barOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import CourseAPI from '@/api/course'
import PaperAPI from '@/api/paper'
import QuestionAPI from '@/api/question'
import UserAPI from '@/api/user'
import Echarts from 'vue-echarts'
import StatisticAPI from '@/api/statistic'
import 'echarts'
export default {
  name: 'OverViewData',
  components: {
    'v-echarts': Echarts
  },
  data() {
    return {
      stuffCount: null,
      courseCount: null,
      questionCount: null,
      paperCount: null,
      dateRange: [],
      barOptions: {
        title: {
          text: '月份资源新增统计图',
          padding: [15, 30]
        },
        xAxis: {
          type: 'category',
          data: ['课程', '资料', '试卷', '试题']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [
              { value: 0, itemStyle: { color: '#6382b3' } },
              { value: 0, itemStyle: { color: '#deb23a' } },
              { value: 0, itemStyle: { color: '#4acacb' } },
              { value: 0, itemStyle: { color: '#de7f71' } }
            ],
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    color: 'black'
                  }
                }
              }
            },
            type: 'bar'
          }
        ],
        animationDuration: 2000
      }
    }
  },
  created() {
    this.dateRange = [this.monthBoundary[0], this.monthBoundary[1]]
    this.requestCourseCount()
    this.requestPaperCount()
    this.requestQuestionCount()
    this.requestUserCount()
    // this.requestMonthUploadCount()
  },
  methods: {
    async requestCourseCount() {
      const { res } = await CourseAPI.getCourseCountStatistic()
      this.courseCount = res
    },
    async requestPaperCount() {
      const { res } = await PaperAPI.getPaperCountStatistic()
      this.paperCount = res
    },
    async requestQuestionCount() {
      const { res } = await QuestionAPI.getQuestionCountStatistic()
      this.questionCount = res
    },
    async requestUserCount() {
      const { res } = await UserAPI.getUserCountStatistic()
      this.stuffCount = res
    },
    async requestMonthUploadCount() {
      const res = await StatisticAPI.getMonthUploadStatistic(this.dateRange[0].getTime(), this.dateRange[1].getTime())
      const barData = [...this.barOptions.series[0].data]
      barData[0].value = res.course
      barData[1].value = res.datum
      barData[2].value = res.paper
      barData[3].value = res.question
    }
  },
  watch: {
    dateRange: {
      handler(newVal) {
        this.requestMonthUploadCount()
      }
    }
  },
  computed: {
    monthBoundary() {
      const now = new Date()
      let currentMonth = now.getMonth() + 1
      const currentYear = now.getFullYear()
      const bigMonth = [1, 3, 5, 7, 8, 10, 12]
      let lastDay
      if (bigMonth.indexOf(currentMonth) !== -1) {
        // 大月 31 天
        lastDay = 31
      } else {
        if (currentMonth !== 2) {
          lastDay = 30
        } else {
          currentYear % 100 === 0 || currentYear % 400 === 0 ? (lastDay = 29) : (lastDay = 28)
        }
      }
      currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth
      return [new Date(`${currentYear}-${currentMonth}-01`), new Date(`${currentYear}-${currentMonth}-${lastDay}`)]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/mixin';
.wrapper > * + * {
  margin-left: 10px;
}
.chart-wrapper {
  border: solid 2px #e3e3e3;
  display: inline-block;
}
</style>
