<template>
  <div class="course-data-layout">
    <v-echarts :options="barChartOption" style="width: 100%; height: 300px" />
    <h2>课程数据</h2>
    <Table :columns="columns" :data="courseDataList">
      <template slot-scope="{ row }" slot="videoAmount">
        <div>{{ row.videoAmount || '0' }}</div>
      </template>
      <template slot-scope="{ row }" slot="liveAmount">
        <div>{{ row.liveAmount || '0' }}</div>
      </template>
      <template slot-scope="{ row }" slot="datumAmount">
        <div>{{ row.datumAmount || '0' }}</div>
      </template>
    </Table>
    <Page :total="total" size="small" @on-change="handlePageChange" />
  </div>
</template>

<script>
import Echarts from 'vue-echarts'
import 'echarts'
import CourseAPI from '@/api/course'
export default {
  name: 'CourseData',
  components: {
    'v-echarts': Echarts
  },
  created() {
    this.requestPopularCourseData()
    this.requestCourseListData()
  },
  methods: {
    async requestPopularCourseData() {
      const { res } = await CourseAPI.getPopularTop10()
      const newOption = { ...this.barChartOption }
      newOption.xAxis.data = res.map(item => {
        return item.name.length > 10 ? `${item.name.substring(0, 10)}...` : item.name
      })
      newOption.series[0].data = res.map(item => item.viewAmount || 0)
      this.barChartOption = newOption
    },
    async requestCourseListData() {
      const { res } = await CourseAPI.getCourseStatisticList(this.currentPage, this.pageSize)
      this.courseDataList = res.data
      this.total = res.total
    },
    handlePageChange(newPage) {
      this.currentPage = newPage
    }
  },
  data() {
    return {
      courseLabelList: [],
      courseVisitCountList: [],
      courseDataList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      barChartOption: {
        title: {
          text: '最受欢迎课程综合访问人数',
          padding: [10, 30]
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>点击量: {c}'
        },
        grid: {
          x: 60, //默认是80px
          y: 60, //默认是60px
          x2: 40,
          y2: 100 //默认60px
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    color: 'black'
                  }
                }
              }
            }
          }
        ]
      },
      columns: [
        {
          key: 'name',
          title: '课程名'
        },
        {
          key: 'category',
          title: '类型',
          width: 120
        },
        {
          key: 'sectionNums',
          title: '课程节数',
          width: 120,
          align: 'center'
        },
        {
          slot: 'videoAmount',
          title: '视频观看节数',
          width: 120,
          align: 'center'
        },
        {
          slot: 'datumAmount',
          title: '资料下载次数',
          width: 120,
          align: 'center'
        },
        {
          slot: 'liveAmount',
          title: '直播观看次数',
          width: 120,
          align: 'center'
        }
      ]
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.requestCourseListData()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
