<template>
  <div class="statistic-overview">
    <Breadcrumb>
      <BreadcrumbItem>统计分析</BreadcrumbItem>
      <BreadcrumbItem>汇总数据</BreadcrumbItem>
    </Breadcrumb>
    <div class="wrapper">
      <Tabs type="card" v-model="currentTab">
        <TabPane v-for="tab in tabs" :key="tab.name" :label="tab.name" :name="tab.key"></TabPane>
      </Tabs>
      <div class="tab-content">
        <keep-alive>
          <OverViewData v-if="currentTab === 'overview'"></OverViewData>
          <StudyData v-if="currentTab === 'study'"></StudyData>
          <StuffStatistic v-if="currentTab === 'stuff'"></StuffStatistic>
          <CourseData v-if="currentTab === 'course'"></CourseData>
          <ExamData v-if="currentTab === 'exam'"></ExamData>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import OverViewData from './OverViewData'
import StudyData from './StudyData'
import StuffStatistic from './StuffStatistic'
import CourseData from './CourseData'
import ExamData from './ExamData'
export default {
  name: 'StatisticOverview',
  components: {
    ExamData,
    CourseData,
    StuffStatistic,
    StudyData,
    OverViewData
  },
  data() {
    return {
      currentTab: 'overview',
      tabs: [
        { name: '整体数据', key: 'overview' },
        { name: '员工数据', key: 'stuff' },
        { name: '学习数据', key: 'study' },
        { name: '课程数据', key: 'course' },
        { name: '考试数据', key: 'exam' },
        { name: '资源数据', key: 'resource' }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/mixin';
.statistic-overview {
  text-align: left;
  padding: 20px;
}
.wrapper {
  margin-top: 10px;
  /*padding: 10px;*/
  /*background: white;*/
  ::v-deep .ivu-tabs-bar {
    margin-bottom: 0;
  }
}
.tab-content {
  .--mixin-shadow;
  padding: 10px;
  background: white;
}
</style>
